
// export const MAIN_URL: string = "http://34.100.205.198:3000/";
// export const MAIN_URL: string = "https://tamil.newsfirst.lk/api/";
export const MAIN_URL: string = "https://apitamil.newsfirst.lk/";
// export const MAIN_URL: string = "http://localhost:3000/";

// export const FRONT_URL: string = "http://34.100.175.63:4000";
export const FRONT_URL: string = "https://tamil.newsfirst.lk";
// export const FRONT_URL: string = "http://localhost:4200/";


export const TITLE: string = "Sri Lanka Tamil News - Newsfirst | News1st | newsfirst.lk | Breaking - #WITHTHEPEOPLE"
export const TITLE_BUSINESS: string = "Business News | Sri Lanka | Financial News | Inflation | Budget"
export const TITLE_SPORT: string = "Sports News | Sri Lanka | Cricket | Rugby | Football | Latest News"
export const TITLE_FEATURED: string = "Featured Archives | Sri Lanka News - Newsfirst"
export const TITLE_LOCAL: string = "Local News | Sri Lanka | News 1st | Top stories | Breaking News | Latest News"
export const TITLE_LATEST: string = "Sri Lanka Latest News Articles Sri Lanka News - Newsfirst"
export const TITLE_WORLD: string = "World News | International News | Top stories | Breaking News | Latest News"
export const TITLE_TIMELINE: string = " | Sri Lanka News - Newsfirst"

export const DESCIPTION_BASE = "Newsfirst.lk is a leading news website in Sri Lanka that provides a wide range of news and information on current events, politics, sports, weather, exams, business, entertainment, and world news. The website is known for its comprehensive coverage of breaking news and top stories from around the country and is a reliable source of up-to-date news and information. In addition to its news coverage, Newsfirst.lk also offers a variety of features and multimedia content, including video reports, podcasts, and photo galleries. Overall, Newsfirst.lk is an excellent resource for staying informed about the latest developments in Sri Lanka and around the world."
export const DESCIPTION_BASE_LOCAL = "Sri Lanka News: Get the latest local news, breaking news and top stories around the country. Keep yourself updated with the News 1st."
export const DESCIPTION_BASE_SPORT = "Sri Lanka News: Cricket News. Access all the latest sports coverage, live reports, analysis and comments. View scores and videos online."
export const DESCIPTION_BASE_WORLD = "World News: Get the latest breaking news headlines and top stories from around the world at newsfirst, Sri Lanka’ premier news reporter."
export const DESCIPTION_BASE_BUSINESS = "Sri Lanka News: Get the latest breaking financial, business and economic news and top stories from our business news page."
export const DESCIPTION_BASE_TIMELINE = "Get the latest breaking news and top stories from Sri Lanka, the latest political news, sports news, weather updates, exam results, business news, entertainment news, world news and much more from News 1st, Sri Lanka&#039;s leading news network."
