<div>
  <div class="toolbar aaa web_header " role="banner" style="position: fixed;z-index: 1001;">

    <div class="main-logo">
      <!-- <div> -->
      <a href={{base_url}} style="text-decoration: none;">
        <img style="height: 58px; width: 95px;" src="https://cdn.newsfirst.lk/assets/NEWS-LOGO-Recovered%20(1).webp"
          alt="sample image">
      </a>
      <!-- </div> -->
    </div>

    <div class="display-flex" style=" height: 35px;">
      <a href="/home" style="text-decoration: none; color: #FFFFFF;">
        <div class="  display-flex    header">
          <mat-icon class="display-flex header-icon">home</mat-icon>
          <mat-label>Home</mat-label>
        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/latest" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex  header">
          <mat-label>சமீபத்திய செய்திகள்</mat-label>
        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/featured" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex   header">
          <mat-label>செய்தித் தொகுப்பு</mat-label>
        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/local" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex header">
          <mat-label>உள்ளூர்</mat-label>
        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/sports" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex  header ">
          <mat-label>விளையாட்டு</mat-label>
        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/business" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex   header">
          <mat-label>வணிகம்</mat-label>

        </div>
      </a>
      <mat-divider vertical class="vertical-divider"></mat-divider>

      <a href="/world" style="text-decoration: none; color: #FFFFFF;">
        <div class=" display-flex  header">
          <mat-label>உலகம்</mat-label>
        </div>
      </a>
    </div>


    <span class="spacer"></span>
    <div class="display-flex" style="height: 35px;position: relative;right: 25px;">


      <div class=" display-flex  " style="    width: 22px; height: 22px; margin-inline: 5px;">
        <a style="text-decoration: none ;width: 100%" href="https://www.facebook.com/NewsfirstTamilSL" target="blank">
          <img loading="lazy" class=" display-flex  " style=" width: 22px; height: 22px"
            src="https://cdn.newsfirst.lk/assets/icons8-facebook-f-24.webp" alt="">
        </a>
      </div>
      <div class=" display-flex  " style="   width: 22px; height: 22px; margin-inline: 5px;">
        <a style="text-decoration: none; width: 100%" href="https://www.instagram.com/newsfirst.tamil/" target="blank">
          <img loading="lazy" class=" display-flex  " style=" width: 22px; height: 22px"
            src="https://cdn.newsfirst.lk/assets/icons8-instagram-24.webp" alt="">
        </a>
      </div>
      <div class=" display-flex  " style="    width: 22px; height: 22px;margin-inline: 5px;">
        <a style="text-decoration: none;width: 100%" href="https://twitter.com/NewsfirstTamil" target="blank">
          <img loading="lazy" class=" display-flex  " style=" width: 22px; height: 22px"
            src="https://cdn.newsfirst.lk/assets/icons8-twitter-squared-24.webp" alt="">
        </a>
      </div>
      <div class=" display-flex  " style="   width: 22px; height: 22px;margin-inline: 5px;">
        <a style="text-decoration: none;width: 100%" href="https://www.youtube.com/@Newsfirst.TamilSL/" target="blank">
          <img loading="lazy" class=" display-flex  " style=" width: 22px; height: 22px"
            src="https://cdn.newsfirst.lk/assets/icons8-youtube-24.webp" alt="">
        </a>
      </div>
      <div class=" display-flex  " style="   width: 22px; height: 22px;margin-inline: 5px;">
        <a style="text-decoration: none;width: 100%" href="https://bit.ly/news1stviber" target="blank">
          <img loading="lazy" class=" display-flex  " style=" width: 22px; height: 22px"
            src="https://cdn.newsfirst.lk/assets/icons8-viber-30.webp" alt="">
        </a>
      </div>



      <div class=" display-flex  "
        style=" width: 70px;    height: 22px;margin-inline: 5px;font-size: 12px;margin-left:10px;">
        <mat-select [value]="selectedLang" (selectionChange)="ChangingValue($event)" panelClass="panel-custom-width">
          <a href="https://english.newsfirst.lk/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option class="dropFont" value="eng"> English</mat-option>
          </a>
          <a href="https://sinhala.newsfirst.lk/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option class="dropFont" value="si">Sinhala</mat-option>
          </a>
          <a href="https://newsfirst.lk/tamil/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option class="dropFont" value="ta">தமிழ்</mat-option>
          </a>
        </mat-select>
      </div>

      <a href="/search" style="text-decoration: none; color: #FFFFFF;">
        <button mat-icon-button class="display-flex search-icon" aria-label="Example icon-button with search icon">

          <mat-icon>search</mat-icon>

        </button>
      </a>


    </div>


  </div>
  <div class="mobile_header">
    <mat-toolbar style="background-color: #2E2E2E;">
      <div
        style="margin-left: 0;height: 100%;padding-top: 0; display: flex;justify-content: center;align-items: center;"
        class="main-logo">
        <div>
          <a href={{base_url}} style="text-decoration: none;">
            <img style="position: relative;    bottom: 0;   margin: auto; height: 48px; width: 78px;"
              src="https://cdn.newsfirst.lk/assets/NEWS-LOGO-Recovered_2.webp" alt="news_1st_logo">
          </a>
        </div>
      </div>
      <span class="spacer"></span>
      <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button> -->

      <!-- <span>My App</span> -->
      <div class=" display-flex  "
        style=" width: 70px;    height: 22px;margin-inline: 5px;font-size: 12px;margin-left:10px;">
        <mat-select [value]="selectedLang" (selectionChange)="ChangingValue($event)" panelClass="panel-custom-width">
          <a href="https://english.newsfirst.lk/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option value="eng"> English</mat-option>
          </a>
          <a href="https://sinhala.newsfirst.lk/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option value="si">Sinhala</mat-option>
          </a>
          <a href="https://tamil.newsfirst.lk/" style="text-decoration: none;color: #2E2E2E; width: 100%;">
            <mat-option value="ta">தமிழ்</mat-option>
          </a>
        </mat-select>
      </div>



      <button mat-icon-button (click)="drawer.toggle()" class="example-icon"
        aria-label="Example icon-button with share icon">
        <mat-icon style="color: #FFFFFF;">menu</mat-icon>
      </button>

      <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>dialpad</mat-icon>
          <span>Redial</span>
        </button>
        <button mat-menu-item disabled>
          <mat-icon>voicemail</mat-icon>
          <span>Check voice mail</span>
        </button>
        <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Disable alerts</span>
        </button>
      </mat-menu> -->
    </mat-toolbar>


    <div *ngIf="selectHome" class="mobile_header_main">


      <div class="hor_scroller">

        <div class="mobile_scroll_main">
          <a href="/home" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/home'?'#FFFFFF':''">
              <div class="display-flex">
                <mat-icon class="display-flex header-icon" [style.color]="routerPath=='/home'?'#2E2E2E':'#FFFFFF'"
                  style=" margin-right: 1px;">home</mat-icon>
                <div [style.color]="routerPath=='/home'?'#2E2E2E !important':'#FFFFFF'"
                  class="mobile_header_inner_text">Home</div>
              </div>

            </div>
          </a>
        </div>


        <div class="mobile_scroll_main">
          <a href="/latest" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/latest'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/latest'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">
                  சமீபத்திய செய்திகள்
                </div>
              </div>

            </div>
          </a>
        </div>

        <div class="mobile_scroll_main">
          <a href="/featured" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/featured'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/featured'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">
                  செய்தித் தொகுப்பு
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="mobile_scroll_main">
          <a href="/sports" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/sports'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/sports'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">
                  விளையாட்டு
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="mobile_scroll_main">
          <a href="/world" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/world'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/world'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">உலகம்
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="mobile_scroll_main">
          <a href="/business" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/business'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/business'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">
                  வணிகம்ம்
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="mobile_scroll_main">
          <a href="/local" style="text-decoration: none; color: #FFFFFF;">
            <div class="mobile_header_inner_div" [style.background]="routerPath=='/local'?'#FFFFFF':''">
              <div class="display-flex">
                <div [style.color]="routerPath=='/local'?'#2E2E2E':'#FFFFFF'" class="mobile_header_inner_text">உள்ளூர்
                </div>
              </div>
            </div>
          </a>
        </div>


      </div>


    </div>
  </div>
</div>


<router-outlet></router-outlet>

<div ngStyle.xs="padding-inline:0" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10"
  fxLayoutGap.xs="0" fxLayoutGap.sm="0" class="adver main_ads ">
  <div fxFlexAlign="center" class=" " fxLayoutAlign="center">
    <a href="https://bit.ly/3STIOhn" target="_blank" rel="noopener">
      <img loading="lazy" style="display: block; width: 100%;   margin:auto"
        src="https://cdn.newsfirst.lk/advertisements/31-01-2024/S24%20Series%20Pre-order%20banner_01_720%20x%2090%20(1).gif"
        alt="" /></a>
  </div>

</div>

<div class=" footer display-flex">

  <div class=" container " fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="85">

    <div fxFlex.lg="40" fxFlex.xl="40" fxFlex.md="40" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start"
      fxLayout="column" fxFlexOrder="1" fxFlexOrder.sm="2" fxFlexOrder.xs="2">
      <div>
        <div fxLayoutAlign="center center">
          <a href={{base_url}} style="text-decoration: none;">
            <img loading="lazy" style="height: 58px; width: 95px;"
              src="https://cdn.newsfirst.lk/assets/NEWS-LOGO-Recovered%20(1).webp" alt="">
          </a>
        </div>


        <div style="margin-top: 10px; font-size: 18px; text-align: center;font-family:Noto Sans Tamil ;"
          fxLayoutAlign="center center">
          நியூஸ்ஃபெஸ்ட், MTV சேனல் (பிரைவேட்) லிமிட்டட், 45/3, பிரேப்ரூக் பிளேஸ், கொழும்பு - 2.
          <br>
          <br>
          தொலைபேசி : +94 114 792 700, தொலைநகல் : +94 114 792 733
        </div>
        <div style="margin-top: 20px; font-size: 15px;" fxLayoutAlign="center center"><a target="_top"
            href="mailto:contact@newsfirst.lk">contact@newsfirst.lk</a>
        </div>
        <div fxLayoutAlign="center center">
          <div class="bottom_img_div">
            <div fxFlex="1 1 auto">
              <a style="text-decoration: none" href="https://twitter.com/NewsfirstSL" target="blank">
                <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/twitter.webp"
                  alt="twitter.png">
              </a>
            </div>
            <div fxFlex="1 1 auto">
              <a style="text-decoration: none" href="https://www.instagram.com/newsfirst.lk/" target="blank">
                <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/instagram_2.webp"
                  alt="instagram.png">
              </a>
            </div>
            <div fxFlex="1 1 auto">
              <a style="text-decoration: none" href="https://www.facebook.com/NewsfirstTamilSL" target="blank">
                <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/facebook_2.webp"
                  alt="facebook.png">
              </a>
            </div>
            <div fxFlex="1 1 auto">
              <a style="text-decoration: none" href="https://bit.ly/news1stviber" target="blank">
                <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/viber.webp" alt="viber.png">
              </a>
            </div>
            <div fxFlex="1 1 auto">
              <a style="text-decoration: none" href="https://www.youtube.com/@Newsfirst.TamilSL/" target="blank">
                <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/youtube.webp"
                  alt="youtube.png">
              </a>
            </div>
            <div fxFlex="1 1 auto">
              <img loading="lazy" class="bottom_img" src="https://cdn.newsfirst.lk/assets/whatsapp_2.webp" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="40" fxLayoutAlign="start" fxLayout="column" fxFlexOrder="2" fxFlexOrder.sm="1" fxFlexOrder.xs="1">
      <div>
        <div class="footer-title" fxLayoutAlign="center center">Categories</div>
        <table style="margin-top: 10px;width: -webkit-fill-available">
          <tr style="min-height: 50px;">
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <mat-icon class="display-flex header-icon">home</mat-icon>
                  <a style="text-decoration: none;" href="/home">
                    <mat-label style="color: black;"> Home </mat-label>
                  </a>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/latest">
                    <mat-label style="color: black;"> Latest </mat-label>
                  </a>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/sports">
                    <mat-label style="color: black;"> Sports </mat-label>
                  </a>
                </div>
              </div>
            </th>
          </tr>
          <tr style="min-height: 50px;">
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <mat-icon class="display-flex header-icon">home</mat-icon>
                  <mat-label>Video</mat-label>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/featured">
                    <mat-label style="color: black;"> Featured </mat-label>
                  </a>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/business">
                    <mat-label style="color: black;"> Business </mat-label>
                  </a>
                </div>
              </div>
            </th>
          </tr>
          <tr style="min-height: 50px;">
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <mat-icon class="display-flex header-icon">home</mat-icon>
                  <mat-label>Live TV</mat-label>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex ">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/local">
                    <mat-label style="color: black;"> Local </mat-label>
                  </a>
                </div>
              </div>
            </th>
            <th>
              <div style="height: 50px;" fxLayoutAlign="start" class="spacer display-flex">
                <div class="  display-flex header-footer">
                  <a style="text-decoration: none;" href="/world">
                    <mat-label style="color: black;"> World </mat-label>
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="footer_new_add" fxFlex="20" fxLayoutAlign="start " fxLayout="column" fxFlexOrder="3" fxFlexOrder.sm="3"
      fxFlexOrder.xs="3">
      <div>
        <div class="footer-title" fxLayoutAlign="center center">Other Channels</div>
        <table style="    width: -webkit-fill-available; ">
          <tr style="height: 120px;">
            <th>
              <a href="https://sirasatv.lk/" target="blank">
                <img loading="lazy" class="channel-logo" src="https://cdn.newsfirst.lk/assets/sirasa-tv-new.webp"
                  alt="sirasa.png">
              </a>
            </th>
            <th>
              <a href="https://www.cmg.lk/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/CMGwebp.webp" alt="cmg.png">
              </a>
            </th>
            <th>
              <a href="https://yfm.lk/liveradio/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/y-fm-new(1).webp" alt="yes_fm.png">
              </a>
            </th>
          </tr>
          <tr style="height: 120px;">
            <th>
              <a href="https://shakthifm.com/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/shakthi-tv-new.webp"
                  alt="shakthi-tv.png">
              </a>
            </th>
            <th>
              <a href="https://tv1.lk/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/TV1.webp" alt="tv1.png">
              </a>
            </th>
            <th>
              <a href="https://sirasatv.lk/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/sirasa%2Btv-5.webp"
                  alt="shakthi-fm.png">
              </a>
            </th>
          </tr>
          <tr style="height: 120px;">
            <th>
              <a href="http://sirasa.com/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/sirasa_fm.webp" alt="sirasa-fm.png">
              </a>
            </th>
            <th>
              <a href="https://yesfmonline.com/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/yes_fm.webp" alt="yes-fm.png">
              </a>
            </th>
            <th>
              <a href="https://legends966.com/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/legends.webp" alt="legends.png">
              </a>
            </th>
          </tr>
          <tr style="height: 120px;">
            <th>

            </th>
            <th>
              <a href="https://me.lk/" target="blank">
                <img loading="lazy" class="channel-logo"
                  src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/Me%20Logo%204848.webp">
              </a>
            </th>
            <th>

            </th>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>

<!--<div *ngIf="showMenuDiv" class="menu_div"
     style="height: 100vh; width: 100%; background-color: #E5E5E5; position: absolute;top: 70px;">
  <div class="display-flex">
    <div></div>
    <div style="width: 100px;">
      <div class="title" style="width: 100%; ">
        <div style="width: 100%; text-align: center; display: flex;" [routerLink]="'/home'">
          <mat-icon class="display-flex header-icon">home</mat-icon>
          <div>Home</div>
        </div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center; display: flex;">
          <mat-icon class="display-flex header-icon">home</mat-icon>
          <div>Videos</div>
        </div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center; display: flex;">
          <mat-icon class="display-flex header-icon">home</mat-icon>
          <div>Live TV</div>
        </div>

      </div>

      <div class="title">
        <div style="width: 100%; text-align: center;">
          Latest
        </div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center;">Featured</div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center;">Local</div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center;">Sports</div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center;">Business</div>
      </div>
      <div class="title">
        <div style="width: 100%; text-align: center;">World</div>
      </div>
    </div>
  </div>
</div>-->
<mat-drawer-container class="example-container" autosize>
  <!--<div class="example-sidenav-content">
  </div>-->
</mat-drawer-container>
<mat-drawer #drawer class="example-sidenav" mode="over" style="width: 100vw;top: 80px">
  <div class="menu_div" style="width: 100%; background-color: #E5E5E5; position: absolute;">
    <div style="margin-top: 20px;">
      <div style="    margin: auto;width: max-content;top: 10px;">
        <div style="display: flex;" fxLayoutGap.xs="5px" fxLayoutGap.sm="5px">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-icon style="padding: 10px;" matPrefix>search</mat-icon>
            <input (keyup.enter)="clickme(search.value,drawer)" matInput type="text" placeholder="search" id="search"
              name="search" #search>
          </mat-form-field>

          <div>
            <button mat-raised-button type="button" (click)="clickme(search.value,drawer)"
              style="width: 70px;height: 70px;background-color: #a20c0d; color: #ffff;" class="display-flex">
              <mat-icon style="padding: 10px;" matPrefix>search</mat-icon>
            </button>

          </div>


        </div>
      </div>
    </div>

    <div class="display-flex">
      <div style="width: 100px;">
        <a href="/home" style="text-decoration: none; color: #FFFFFF;">
          <div class="title" style="width: 100%; ">

            <div style="width: 100%; text-align: center; display: flex;" (click)="test('home',drawer)">
              <mat-icon class="display-flex header-icon">home</mat-icon>
              <div>Home</div>
            </div>
          </div>
        </a>
        <!-- <div class="title">
          <div style="width: 100%; text-align: center; display: flex;" (click)="test('video',drawer)">
            <mat-icon class="display-flex header-icon">home</mat-icon>
            <div>Videos</div>
          </div>
        </div>
        <div class="title">
          <div style="width: 100%; text-align: center; display: flex;" (click)="test('liveTv',drawer)">
            <mat-icon class="display-flex header-icon">home</mat-icon>
            <div>Live TV</div>
          </div>

        </div> -->


        <a href="/latest" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center;" (click)="test('latest',drawer)">
              Latest
            </div>
          </div>
        </a>


        <a href="/featured" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center; " (click)="test('featured',drawer)">Featured</div>
          </div>
        </a>


        <a href="/local" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center;" (click)="test('local',drawer)">Local</div>
          </div>
        </a>


        <a href="/sports" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center;" (click)="test('sports',drawer)">Sports</div>
          </div>
        </a>


        <a href="/business" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center;" (click)="test('business',drawer)">Business</div>
          </div>
        </a>


        <a href="/world" style="text-decoration: none; color: #FFFFFF;">
          <div class="title">
            <div style="width: 100%; text-align: center;" (click)="test('world',drawer)">World</div>
          </div>
        </a>

      </div>
    </div>
  </div>
</mat-drawer>
<div style="    width: 30px;
height: 30px;
background: #C03434;
color: white;
bottom: 10px;
position: sticky;
padding: 5px;
margin: auto;
right: 10px;
z-index: 999999;
float: right;
border-radius: 100px;cursor: pointer;" (click)="moveUp()">
  <img style="width: 100%;height: 100%;" src="https://storage.googleapis.com/cdn-newsfirst-lk-v2/statics/up.png" alt="">
  <!-- Top -->
</div>
